<template>
  <div class="order-undeliverable">
    <div class="order-undeliverable-trigger" @click="show = !show">
      Unable to deliver
    </div>
    <div v-if="show" class="order-undeliverable-form">
      <b-form-group class="mb-0">
        <label for="Cancel Reason"> Add reason </label>
        <v-select
          name="Cancel Reason"
          v-model="reason"
          :options="options"
          class="custom-select"
          :clearable="false"
          :searchable="false"
          :selectable="(option) => !option.disabled"
        />
      </b-form-group>
      <b-form-group>
        <label for="Cancel Message"> Add comment </label>
        <b-form-input v-model="comment" />
      </b-form-group>
      <b-button :disabled="reason.id === null" variant="success" @click="cancelOrder">Send</b-button>
    </div>
  </div>
</template>

<script>
import Toast from '@/components/Toast.vue'

export default {
  props: {
    options: { type: Array, required: true },
    value: {
      type: Object,
      default: () => ({ id: null, label: 'Select an option' }),
    },
    order: { type: Object, required: true },
  },
  data() {
    return {
      show: false,
      reason: this.value,
      comment: '',
    }
  },
  methods: {
    cancelOrder() {
      this.$Orders
        .updateResourceByUrl({
          url: this.order['@id'],
          body: {
            cancelReason: this.reason.id,
            cancelDescription: this.comment,
            status: 'CANCELLED',
          },
        })
        .then(response => {
          if (response.status === 200) {
            this.$helper.showToast(
              this.$toast,
              Toast,
              'Order canceled',
              '',
              'success',
            )
            this.$emit('orderCanceled', this.order)
          }
        })
        .catch(() => {
          this.$helper.showToast(
            this.$toast,
            Toast,
            'Failed to cancel order',
            '',
            'danger',
          )
        })
    },
  },
}
</script>
