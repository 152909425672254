<template>
  <label class="custom-switch">
    <input v-model="val" type="checkbox" />
    <div class="slider round">
      <span class="off">Off</span>
      <span class="on">On</span>
    </div>
  </label>
</template>

<script>
export default {
  methods: {},
  props: {
      value: { type: Boolean, required: true }
  },
  data () {
      return {
          val: this.value
      }
  },
  watch: {
      val(value){
          this.val = value
          this.$emit('input', this.val)
      }
  }
}
</script>
