<template>
  <div>
    <b-pagination
      class="custom-table-pagination"
      pills
      v-model="current"
      :total-rows="total"
      :per-page="perPageNum"
    ></b-pagination>
  </div>
</template>

<script>
import { BPagination } from 'bootstrap-vue'

export default {
  name: 'TablePagination',
  components: {
    BPagination,
  },
  data() {
    return {
      current: this.value,
      pages: 1,
      perPageNum: this.perPage
    }
  },
  props: {
    value: { type: Number, default: 1 },
    total: { type: Number, default: 0 },
    perPage: { type: Number, default: 10 },
    pageChanged: { type: Function },
    perPageChanged: { type: Function },
  },
  watch: {
    current(item) {
      this.$emit('input', item)
      this.setPage(item)
    },
  },
  created() {
    this.calPageCount()
  },
  methods: {
    setPage(page) {
      this.current = page
      this.pageChanged({ current: this.current })
    },
    previous() {
      if (this.current > 0) this.setPage(this.current - 1)
    },
    next() {
      if (this.current < this.pages) this.setPage(this.current + 1)
    },
    setPerPage(perPage) {
      this.perPageNum = perPage
      this.calPageCount()
      this.perPageChanged({ currentPerPage: this.perPage })
    },
    calPageCount() {
      if (this.total > this.perPage) {
        this.pages = Math.round(this.total / this.perPage)
      } else {
        this.pages = 1
        this.setPage(1)
      }
    },
  },
}
</script>
