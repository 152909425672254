<template>
    <b-modal
      v-if="orderToPay"
      v-model="showModal"
      :dialog-class="paid ? '' : 'no-header'"
      :content-class="contentClass"
      @hidden="closed"
    >
      <template #modal-header>
        {{ paid ? 'Send order summary by email?' : '' }}
        <div @click="showModal = false" class="modal-close">
          <img src="@/assets/images/icons/xmark.svg" alt="" />
        </div>
      </template>

      <div v-if="startDelivery">
        Start delivery?
      </div>
      <div v-else-if="paid || orderToPay.paymentMethod === 'BANK_TRANSFER'">
        <div @click="confirm(false)" class="send">Delivery completed</div>
      </div>
      <div v-else>
        <b-form-group label="Payment selection" v-slot="{ ariaDescribedby }">
          <b-form-checkbox-group
            v-model="payment"
            @change="paymentChange"
            :aria-describedby="ariaDescribedby"
            name="payment"
          >
            <b-form-checkbox size="lg" value="CASH">{{ $helper.getEnumTranslation('order_payment_method', 'CASH') }}</b-form-checkbox>
            <b-form-checkbox size="lg" value="CARD">{{ $helper.getEnumTranslation('order_payment_method', 'CARD') }}</b-form-checkbox>
          </b-form-checkbox-group>
        </b-form-group>

        <b-form-group
          class="mb-0"
          label="Send order summary by email?"
          v-slot="{ ariaDescribedby }"
        >
          <b-form-checkbox-group
            v-model="emailOptions"
            @change="emailChange"
            :aria-describedby="ariaDescribedby"
            name="email"
          >
            <b-form-checkbox size="lg" :value="true">Yes</b-form-checkbox>
            <b-form-checkbox size="lg" :value="false">No</b-form-checkbox>
          </b-form-checkbox-group>
        </b-form-group>
      </div>

      <template v-if="paid" #modal-footer>
        <div @click="showModal = false">Close</div>
      </template>
      <template v-else #modal-footer>
        <div @click="confirm">Confirm</div>
      </template>
    </b-modal>
</template>

<script>
import Toast from '@/components/Toast.vue'

export default {
  props: {
    type: { type: Boolean, default: false },
    show: { type: Boolean, default: false },
    order: { type: Object, default: () => {} },
    startDelivery: { type: Boolean, default: false }
  },
  data() {
    return {
      emailOptions: [true],
      showModal: this.show,
      paid: this.type,
      orderToPay: this.order,
      contentClass: '',
      payment: (this.orderToPay && this.orderToPay.paymentMethod) ? [this.orderToPay.paymentMethod] : ['CASH']
    }
  },
  watch: {
    show: {
      deep: true,
      handler(val) {
        this.showModal = val
      },
    },
    type: {
      deep: true,
      handler(val) {
        this.paid = val
      },
    },
    order: {
      deep: true,
      handler(val) {
        this.orderToPay = val
      },
    },
  },
  methods: {
    closed() {
        this.contentClass = ''
        this.$emit('modalClosed')
    },
    paymentChange() {
      if (this.payment.length) {
        this.payment = [this.payment[this.payment.length - 1]]
      }
    },
    emailChange() {
      if (this.emailOptions.length) {
        this.emailOptions = [this.emailOptions[this.emailOptions.length - 1]]
      }
    },
    confirm(sendReceipt) {
      this.contentClass = 'processing'

      const body = {}
      let sucMessage = ''
      let errMessage = ''
      let emit = ''

      if (this.startDelivery) {
        body.status = 'PROCESSING'
        sucMessage = 'Order delivery started'
        errMessage = 'Failed to start delivery process'
        emit = 'deliveryStarted'
      } else {
        body.status = 'CLOSED'

        if (sendReceipt) {
            body.sendOrderEmail = true
        }

        if(!this.paid) {
            body.financialStatus = 'PAID'
            // eslint-disable-next-line prefer-destructuring
            body.sendOrderEmail = this.emailOptions[0]
            if(this.orderToPay.paymentMethod !== 'BANK_TRANSFER') {
              // eslint-disable-next-line prefer-destructuring
              body.paymentMethod = this.payment[0]
            }
        }

        sucMessage = 'The order was successfully delivered'
        errMessage = 'Failed to deliver this order'
        emit = 'orderDelivered'
      }

      this.$Orders
        .updateResourceByUrl({
          url: this.orderToPay['@id'],
          body,
        })
        .then(response => {
          if (response.status === 200) {
            this.$helper.showToast(
              this.$toast,
              Toast,
              sucMessage,
              '',
              'success',
            )
            this.$emit(emit)
            this.showModal = false
            this.closed()
          }
        })
        .catch(() => {
          this.$helper.showToast(
            this.$toast,
            Toast,
            errMessage,
            '',
            'danger',
          )
        })
    },
  },
}
</script>
