import enums from '@/locales/enum-en.json'

export default {
    data() {
        return {
            userData: JSON.parse(localStorage.getItem('userData')),
        }
    },
    methods: {
        getEnumList(names, ...lists) {
            if (!(names instanceof Array)) {
                names = [names]
            }
            this.$Enums.getCollection({ params: { name: names } })
                .then(response => {
                    names.forEach((name, index) => {
                        response.data['hydra:member'].forEach(element => {
                            if (name === element.name) {
                                element.keys.forEach(key => {
                                    lists[index].push({
                                        label: enums[name][key],
                                        id: key,
                                    })
                                })
                            }
                        })
                    })
                })
        },
        getUserList(args, ...lists) {
            const { params } = args
            const headers = {
                fields: '"/hydra:member/*/givenName", "/hydra:member/*/@id", "/hydra:member/*/id", "/hydra:member/*/familyName", "/hydra:member/*/role", "/hydra:member/*/enabled"',
            }
            params.pagination = false
            if (!(params.role instanceof Array)) {
                params.role = [params.role]
            }

            this.$Users.getCollection({ params, headers })
                .then(response => {
                    response.data['hydra:member'].forEach(element => {
                        params.role.forEach((role, index) => {
                            if (role === element.role) {
                                lists[index].push({
                                    label: `${element.givenName} ${element.familyName}`,
                                    id: element['@id'],
                                    numberId: element.id,
                                })
                            }
                        })
                    })
                })
        },
    },
}
