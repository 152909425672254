<template>
    <a :href="`tel:${number}`" class="call-btn">
      <img
      src="@/assets/images/icons/phone.svg"
      alt="blue-phone-icon"
    >
    </a>
</template>

<script>
export default {
  name: 'CallBtn',
  props: {
    number: String,
  },
}
</script>
