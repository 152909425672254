<template>
  <div class="courier-orders-listing">
    <title-bar title="Orders" />

    <div class="courier-options" v-if="onOff === false || car.id === null">
      <div class="courier-options-line">
        <div class="courier-options-line-label">Assigned car</div>
        <v-select
          v-model="car"
          @input="onCarChange"
          :options="cars"
          class="custom-select"
          :clearable="false"
          :searchable="false"
          :selectable="(option) => !option.disabled"
        />
      </div>
      <div class="courier-options-line">
        <div class="courier-options-line-label">Cash</div>
        <div class="courier-options-line-cash">{{ cash | price }}</div>
      </div>
    </div>

    <div class="courier-availability" v-if="onOff === false || car.id === null">
      <h4>Courier availability</h4>
      <custom-switch v-model="onOff" />
    </div>

    <b-tabs
      v-if="onOff === true && car.id !== null"
      pills
      class="order-listing-tabs"
      v-model="tabIndex"
      @input="loadOrders"
    >
      <b-tab v-for="(tab, index) of tabs" :key="index" :title="tab.title">
        <vue-good-table
          class="custom-table-style"
          mode="remote"
          v-if="tabIndex === index"
          :totalRows="pages"
          :isLoading.sync="isLoading"
          :columns="columns"
          :rows="orders"
          :pagination-options="{
            enabled: pages > perPage,
            perPage: perPage,
            setCurrentPage: currentPage,
          }"
          @on-page-change="onPageChange"
        >
          <template slot="table-row" slot-scope="props">
            <span
              :class="props.column.field"
              v-if="props.column.field == 'status'"
            >
              <table-bullet :value="props.formattedRow[props.column.field]" />
            </span>
            <span
              :class="props.column.field"
              v-else-if="props.column.field == 'shippingAddress'"
            >
              <div class="order-info">
                <div class="order-info-flex">
                  <div class="time-container">
                    <span class="number">#{{ props.row.id }}</span>
                    <div class="time">
                      <span>{{ props.row.createdAt | moment('HH:mm') }}</span>
                      <img
                        src="@/assets/images/icons/arrow-right-small.svg"
                        alt="arrow-right"
                      />
                      <span v-if="props.row.status === 'NEW'">Unknown</span>
                      <span v-else-if="props.row.status === 'CLOSED'">{{
                        props.row.closedAt | moment('HH:mm')
                      }}</span>
                      <span v-else>{{
                        $helper.getEnumTranslation(
                          'order_status',
                          props.row.status,
                        )
                      }}</span>
                    </div>
                  </div>
                  <div class="name">
                    {{ props.formattedRow[props.column.field].streetAddress }} {{ props.formattedRow[props.column.field].streetNumber }} {{ props.formattedRow[props.column.field].postalCode }}
                  </div>
                  <div
                    class="place"
                    v-if="
                      props.formattedRow[props.column.field].flatNumber ||
                      props.formattedRow[props.column.field].floorNumber ||
                      props.formattedRow[props.column.field].gate
                    "
                  >
                    <span class="entrance">Entrance: {{ props.formattedRow[props.column.field].gate }}</span>
                    <span class="flat">Flat: {{ props.formattedRow[props.column.field].flatNumber }}</span>
                    <span class="floor">Floor: {{ props.formattedRow[props.column.field].floorNumber }}</span>
                  </div>
                  <div
                    class="place"
                    v-if="props.row.shippingDate"
                  >
                    <span class="entrance">Delivery: {{ `${$helper.formatDate(props.row.shippingDate)} ${$helper.formatTime(props.row.shippingDate)}` }}</span>
                  </div>
                </div>
              </div>
            </span>
            <span class="buttons" v-else-if="props.column.field == 'id'">
              <div class="just-buttons">
                <a
                  target="_blank"
                  :href="`https://www.google.com/maps/search/?api=1&query=${props.row.shippingAddress.latitude}%2C${props.row.shippingAddress.longitude}`"
                  class="custom-btn ml-0"
                  v-if="
                    props.row.shippingAddress &&
                    props.row.shippingAddress.latitude &&
                    props.row.shippingAddress.longitude
                  "
                >
                  <img src="@/assets/images/icons/pin.svg" />
                </a>
                <b-button
                  @click="openDetail(props.row)"
                  v-b-toggle="
                    `collapse-${props.formattedRow[props.column.field]}`
                  "
                  class="custom-btn custom-collapse"
                >
                  <img src="@/assets/images/icons/arrow-up.svg" />
                </b-button>
              </div>
              <div class="collapsible-content-row">
                <b-collapse
                  :id="`collapse-${props.formattedRow[props.column.field]}`"
                >
                  <div class="order-client">
                    <div class="client">
                      <div class="client-name mr-auto">
                        {{ (props.row.user && props.row.user.givenName) ? props.row.user.givenName : '' }} {{ (props.row.user && props.row.user.familyName) ? props.row.user.familyName : '' }}
                      </div>
                      <div v-if="index === 0" class="client-buttons d-flex">
                        <call-btn
                          class="mr-3"
                          v-if="props.row.telephone"
                          :number="props.row.telephone"
                        />
                        <b-button
                          variant="success"
                          @click="initDelivery(props.row)"
                          v-if="props.row.status === 'NEW'"
                          >Start delivery</b-button
                        >
                        <b-button
                          @click="pay(props.row)"
                          variant="success"
                          v-if="props.row.status === 'PROCESSING'"
                        >
                          {{
                            props.row.financialStatus === 'PAID'
                              ? 'Deliver'
                              : 'Pay'
                          }}
                        </b-button>
                      </div>
                    </div>
                    <div class="payment">
                      <div class="payment-total">
                        {{ props.row.totalPrice | price }}
                      </div>
                      <div class="payment-method">
                        {{
                          $helper.getEnumTranslation(
                            'order_payment_method',
                            props.row.paymentMethod,
                          )
                        }}
                      </div>
                    </div>
                  </div>

                  <div v-if="props.row.description" class="order-note">
                      <label for="">Order note</label>
                      {{ props.row.description }}
                  </div>

                  <div class="ordered-items">
                    <div
                      v-for="(item, itemIndex) of props.row.items"
                      :key="itemIndex"
                      class="ordered-item"
                    >
                      <div class="item">
                        <div class="amount">{{ item.quantity }} pcs</div>
                        <div class="item-name">{{ item.name }}</div>
                      </div>
                      <div class="item-price">{{ item.price | price }}</div>
                    </div>

                    <div class="total-order-price">
                      <span>Total</span>
                      <span>{{ props.row.totalPrice | price }}</span>
                    </div>
                  </div>

                  <table-cancel-form
                    v-if="index === 0"
                    :order="props.row"
                    :options="cancelOptions"
                    @orderCanceled="resetView"
                  />

                  <div
                    v-if="
                      index === 1 &&
                      (props.row.cancelReason || props.row.cancelDescription)
                    "
                    class="order-cancel-info"
                  >
                    <div
                      v-if="props.row.cancelReason"
                      class="order-cancel-info-line"
                    >
                      <div class="order-cancel-info-line-title">
                        Reason of cancelation
                      </div>
                      <div class="order-cancel-info-line-text">
                        {{
                          $helper.getEnumTranslation(
                            'order_cancel_reason',
                            props.row.cancelReason,
                          )
                        }}
                      </div>
                    </div>
                    <div
                      v-if="props.row.cancelDescription"
                      class="order-cancel-info-line"
                    >
                      <div class="order-cancel-info-line-title">
                        Cancelation comment
                      </div>
                      <div class="order-cancel-info-line-text">
                        {{ props.row.cancelDescription }}
                      </div>
                    </div>
                  </div>
                </b-collapse>
              </div>
            </span>
          </template>

          <template slot="pagination-bottom" slot-scope="props">
            <table-pagination
              v-model="currentPage"
              :total="pages"
              :per-page="perPage"
              :pageChanged="props.pageChanged"
              :perPageChanged="props.perPageChanged"
            />
          </template>
        </vue-good-table>
      </b-tab>
    </b-tabs>

    <table-payment-modal
      :show="modalShow"
      :order="orderToPay"
      :type="modalType"
      :startDelivery="startDelivery"
      @modalClosed="modalClosed"
      @orderDelivered="resetView"
      @deliveryStarted="resetView"
    />
  </div>
</template>

<script>
import { BTabs } from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import { PushNotifications } from '@capacitor/push-notifications'
import TitleBar from '@/components/TitleBar.vue'
import TableBullet from '../../components/TableBullet.vue'
import TablePagination from '../../components/TablePagination.vue'
import TableCancelForm from '../../components/TableCancelForm.vue'
import TablePaymentModal from '../../components/TablePaymentModal.vue'
import ListUtils from '@/mixins/ListUtils'
import CallBtn from '../../components/Buttons/CallBtn.vue'
import CustomSwitch from '../../components/Inputs/Switch.vue'
import Toast from '@/components/Toast.vue'

export default {
  components: {
    TitleBar,
    BTabs,
    VueGoodTable,
    TableBullet,
    TablePagination,
    TableCancelForm,
    TablePaymentModal,
    CallBtn,
    CustomSwitch,
  },
  mixins: [ListUtils],
  data() {
    return {
      cancelOptions: [{ id: null, label: 'Select an option', disabled: true }],
      showCancelForm: false,
      onOff: this.$store.state.onOff,
      userData: JSON.parse(localStorage.getItem('userData')),
      car: { id: null, label: 'Select an option', disabled: true },
      cars: [{ id: null, label: 'Select an option', disabled: true }],
      cash: 0,
      isLoading: false,
      tabs: [
        {
          title: 'Pending orders',
        },
        {
          title: 'Order history',
        },
      ],
      tabIndex: 0,
      perPage: 15,
      currentPage: 1,
      pages: 0,
      pendingStates: ['NEW', 'PROCESSING', 'POSTPONED'],
      historyStates: ['CANCELLED', 'CLOSED', 'UNABLE_TO_DELIVERED'],
      columns: [
        {
          field: 'status',
          width: '24px',
          sortable: false,
        },
        {
          field: 'shippingAddress',
          sortable: false,
        },
        {
          field: 'id',
          sortable: false,
          tdClass: 'collapsible',
          width: '50px',
        },
      ],
      orders: null,
      // modal
      modalShow: false,
      orderToPay: null,
      modalType: null,
      startDelivery: false
    }
  },
  watch: {
    onOff(value) {
      this.$Users
        .updateResourceByUrl({
          url: this.userData['@id'],
          body: { onOff: value },
        })
        .then(response => {
          if (response.status === 200) {
            this.$store.dispatch('setCourierVisibility', value)
          }
        })
        .catch(() => {
          this.$helper.showToast(
            this.$toast,
            Toast,
            'Unable to set availability',
            '',
            'danger',
          )
        })
    },
  },
  created() {
    PushNotifications.addListener('pushNotificationReceived', notification => {
      if(notification) {
        this.loadOrders()
      }
    })
    PushNotifications.addListener('pushNotificationActionPerformed', notification => {
      if(notification) {
        this.loadOrders()
      }
    })
    this.loadOrders()

    this.getEnumList('order_cancel_reason', this.cancelOptions)

    if (this.onOff === false || this.car.id === null) {
      this.$Cars.getCollection({}, 'car_list').then(response => {
        response.data['hydra:member'].forEach(car => {
          const carObj = {
            label: car.name,
            id: car['@id'],
            numberId: car.id,
          }
          if (car.courier) {
            carObj.disabled = true
          }
          if (car.courier && car.courier['@id'] === this.userData['@id']) {
            this.car = { id: car['@id'], label: car.name }
          }
          this.cars.push(carObj)
        })
      })

      this.cash = 0
      // eslint-disable-next-line prefer-template
      const url = '/cash_registries/courier/' + this.userData.id + '/balance'
      this.$CashRegistries.getResourceByUrl({ url }).then(response => {
        if (response.data) {
          this.cash = response.data.balance
        }
      })
    }
  },
  methods: {
    pay(order) {
      this.orderToPay = order
      this.modalShow = true
      this.modalType = order.financialStatus === 'PAID'
    },
    initDelivery(order) {
      this.orderToPay = order
      this.modalShow = true
      this.startDelivery = true
    },
    modalClosed() {
      this.orderToPay = null
      this.modalShow = false
      this.modalType = null
      this.startDelivery = false
    },
    loadOrders() {
      const params = {
        itemsPerPage: this.perPage,
        page: this.currentPage,
        courier: [this.userData['@id']],
        'order[position]': 'asc',
        'order[assignedAt]': 'asc'
      }
      if (this.tabIndex === 0) {
        params.status = this.pendingStates
      } else {
        params.status = this.historyStates
      }
      this.$Orders.getCollection({ params }, 'order_list').then(response => {
        this.orders = response.data['hydra:member']
        this.pages = response.data['hydra:totalItems']
      })
    },
    onCarChange() {
      this.updateCarCourier()
    },
    updateCarCourier() {
      this.$Cars
        .updateResourceByUrl({
          url: this.car.id,
          body: { courier: this.userData['@id'] },
        })
        .then(response => {
          if (response.status === 200) {
            this.$helper.showToast(
              this.$toast,
              Toast,
              "Courier's car changed",
              '',
              'success',
            )
            this.$store.dispatch('setCourierCar', this.car.id)
          }
        })
        .catch(() => {
          this.$helper.showToast(
            this.$toast,
            Toast,
            'You do not have a permisssion to change a car',
            '',
            'danger',
          )
        })
    },
    openDetail(order) {
      this.windowResize()
      if (!order.confirmed) {
        this.windowResize()
        this.$Orders
          .updateResourceByUrl({
            url: order['@id'],
            body: { confirmed: true },
          })
          .then(() => {
            order.confirmed = true
          })
      }
    },
    windowResize() {
      const w = document.querySelector('#vgt-table').offsetWidth
      const collapsibleRows = document.querySelectorAll(
        '.collapsible-content-row',
      )
      collapsibleRows.forEach(row => {
        const bw = row.closest('.buttons').clientWidth
        row.style.width = `${w}px`
        row.style.marginLeft = `-${w - bw}px`

        let h = 0
        const cols = row.closest('tr').querySelectorAll('td')
        cols.forEach(col => {
          const span = col.querySelector(':scope > span:not(.buttons)')
          if (span && h < span.clientHeight) {
            h = span.clientHeight
          }
        })
        row.style.marginTop = `${h - 30}px`
      })
    },
    onPageChange() {
      this.loadOrders()
    },
    resetView() {
      this.currentPage = 1
      this.loadOrders()
    },
  },
}
</script>
